import React from "react";

function getYoutubeEmbedUrl(youtubeShareLink) {
  if (youtubeShareLink.includes("https://youtu.be/")) {
    return "https://www.youtube.com/embed/" + youtubeShareLink.split(".be/")[1];
  } else if (youtubeShareLink.includes("https://www.youtube.com/")) {
    if (youtubeShareLink.includes("watch?v=")) {
      return (
        "https://www.youtube.com/embed/" + youtubeShareLink.split("watch?v=")[1]
      );
    } else {
      return (
        "https://www.youtube.com/embed/" +
        youtubeShareLink.split("https://www.youtube.com/")[1]
      );
    }
  }
}
function YoutubeVideo({ video, classname = "", id = "" }) {
  if (!video) {
    return null;
  }
  return (
    <iframe
      id={id}
      className={classname}
      src={getYoutubeEmbedUrl(video)}
      frameBorder="0"
      title={video.title ? video.title : "Youtube video"}
      allow="accelerometer; autoplay;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
}

export default YoutubeVideo;
